.App {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.beta {
  position: relative;
  font-size: 0.5em;
  top: -15px;
}

.navigation li {
  list-style: none;

  display: inline;
}

.example img {
  margin: 0 auto;
}

.ptCalculator p {
  margin-bottom: 0;
}

.repos {
  text-align: left;
}

.repo .MuiButtonBase-root {
  margin-top: 16px;
  margin-left: 10px;
}

.repos li {
  list-style: none;
  font-size: 1.3em;
  line-height: 1.5em;
}

.repos li input {
  margin-left: 10px;
  border: none;
  border-bottom: 1px solid #666;
}
.repos ul.graphs {
  padding: 10px;
}
.repos ul.graphs li {
  display: inline;
  padding: 10px;
}
.repos li img {
  width: 100px;
}

noticeable-widget {
  float: right;
}

.footer {
  border-top: 1px solid black;
  margin-top: 20px;
  padding-top: 20px;
}

.footer .legal {
  list-style: none;
  margin: 0;
  margin-bottom: 10px;

}
.footer .legal li {
  display: inline;
  padding: 0 10px 0;
}

.paid table {
  margin: 0 auto 50px;
  width: 600px;
}
.paid table tr {
  line-height: 2em;
}
.paid table tr.dark {
  background-color: #ddd;
}
.paid table td {
  padding-left: 20px;
  padding-right: 20px;
}

code.dot {
  display: block;
  white-space: pre-wrap;
}
code.dot * {
  line-height: 1em;
}

.center {
  text-align: center;
}

.pointillism-example {
  margin: 30px 100px 30px;
}
.pointillism-example code {
  padding: 10px;
  background-color: #ddd;
}

.ReactModal__Content {
  width: 500px;
  margin: 0 auto;
  height: 150px;
}

fieldset {
  width: 500px;
  margin: 0 auto 20px;
  padding: 10px 20px;
}

.subtext {
  color: #555;
  font-size: 0.9em;
}

#showcase {
  width: 800px;
}